import HeaderComponent from "../Components/HeaderComponent";
import FooterTopComponent from "../Components/FooterTopComponent";
import FooterBottomComponent from "../Components/FooterBottomComponent";
import React, {useEffect} from "react";
import PromoBlockComponent from "../Components/PromoBlockComponent";
import TechnologiesComponent from "../Components/TechnologiesComponent";

// import HowWeCanHelpComponent from "./Components/HowWeCanHelpComponent";
// import PricingPlansComponent from "./Components/PricingPlansComponent";
// import PortfolioWidgetComponent from "./Components/PortfolioWidgetComponent";

export default function HomePage() {

    useEffect(() => {
        document.title = 'Strony internetowe consulting IT | pyCode.biz';
    });

    return (
        <main>
            <HeaderComponent/>
            <PromoBlockComponent/>
            <TechnologiesComponent/>
            {/*<HowWeCanHelpComponent/>*/}
            {/*<PricingPlansComponent/>*/}
            {/*<PortfolioWidgetComponent/>*/}
            <FooterTopComponent/>
            <FooterBottomComponent/>
        </main>
    );
}
