const config = {
    title: "",
    topBar: false,
    pages: {
        enabled: false,
        gdpr: {
            url: "/rodo",
            title: "RODO",
        },
        cookies: {
            url: "/ciasteczka",
            title: "Ciasteczka UE",
        },
        privacy: {
            url: "/polityka-prywatnosci",
            title: "Polityka prywatności",
        },
        terms: {
            url: "/regulamin",
            title: "Regulamin",
        }
    },
    status: {
        enabled: false,
        url: "//https://pycode.biz/status",
        title: "Sprawdź status usług"
    },
    portfolio: {
        enabled: false,
        title: "",
        url: ""
    },
    accounts: {
        enable: true,
        profile: {
            url: "/profil",
            title: "Mój profil",
        },
        login: {
            url: "/login",
            title: "Zaloguj",
        },
        logout: {
            url: "/logout",
            title: "Wyloguj",
        },
        registration: {
            url: "/rejestracja",
            title: "Rejestracja",
        },
    },
    services: {
        types: {
            main: {
                enabled: false,
                title: "Produkty i usługi",
                url: "/produkty-i-uslugi/"
            },
            hosting: {
                enabled: false,
                title: "Hosting",
                url: "/produkty-i-uslugi/hosting"
            },
            webdesign: {
                enabled: false,
                title: "Web design",
                url: "/produkty-i-uslugi/web-design"
            },
            customApplication: {
                enabled: false,
                title: "Aplikacje dedykowane",
                url: "/produkty-i-uslugi/dedykowane-aplikacje"
            },
            psd2html: {
                enabled: false,
                title: "Kodownie szablonów",
                url: "/produkty-i-uslugi/ciecie-psd-do-html"
            },
            dynamicDns: {
              enabled: false,
              title: 'Dynamiczny DNS',
              url: '/produkty-i-uslugi/dynamiczny-adres-ip'
            },
        }
    },
    helpForm: {
        enabled: false
    },
    pricings: {
        enabled: false
    },
    contact: {
        enabled: true,
        url: "/kontakt",
        title: "Kontakt",
        endpoint: {
            lambda_url: "",
            captcha: "",
        }
    }
};


export default config;
