import HeaderComponent from "../../Components/HeaderComponent";
import FooterTopComponent from "../../Components/FooterTopComponent";
import FooterBottomComponent from "../../Components/FooterBottomComponent";
import React, {useEffect} from "react";

export default function MainServicesPage() {

    useEffect(() => {
        document.title = 'Nasze produkty i usługi | pyCode.biz';
    });

    return (
        <main>
            <HeaderComponent/>

            <section
                className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall"
                data-options="{direction: &quot;fromtop&quot;, animation_duration: 25, direction: &quot;reverse&quot;}">
                <div
                    className="divimage dzsparallaxer--target w-100 g-bg-cover g-bg-size-cover g-bg-pos-top-center g-bg-black-opacity-0_3--after"
                    style={{
                        height: '140%',
                        backgroundImage: "url('/assets/img-temp/1920x800/img11.jpg')",
                        transform: 'translate3d(0px, -86.7475px, 0px)'
                    }}/>
                <div className="container g-color-white text-center g-py-150">
                    <h2 className="h3 text-uppercase mb-2">Produkty i usługi</h2>
                </div>
            </section>
            <section className="g-bg-secondary">
                <div className="container g-pt-100 g-pb-40">
                    <div className="row justify-content-center g-mb-60">
                        <div className="col-lg-7">
                            <div className="text-center">
                                <h2 className="h3 g-color-black text-uppercase mb-2">Our process</h2>
                                <div className="d-inline-block g-width-35 g-height-2 g-bg-primary mb-2"/>
                                <p className="lead mb-0">We are a creative person focusing on culture, luxury,
                                    editorial &amp; art. Somewhere between sophistication and simplicity.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-lg-3 g-mb-60">
                            <div className="text-center">
                                <i className="u-dot-line-v1-2 g-brd-transparent--before g-brd-gray-light-v2--after g-mb-20">
                                    <span
                                        className="u-dot-line-v1__inner g-bg-white g-bg-primary--before g-brd-gray-light-v2"/>
                                </i>
                                <h3 className="h5 g-color-black mb-20">Consult</h3>
                                <p className="mb-0">This is where we sit down, grab a cup of coffee and dial in
                                    the details.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 g-mb-60">
                            <div className="text-center">
                                <i className="u-dot-line-v1-2 g-brd-gray-light-v2--before g-brd-gray-light-v2--after g-mb-20">
                                    <span className="u-dot-line-v1__inner g-bg-white g-bg-primary--before g-brd-gray-light-v2"/>
                                </i>
                                <h3 className="h5 g-color-black mb-20">Plan</h3>
                                <p className="mb-0">Now that we've aligned the details, it's time to get things
                                    organized.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 g-mb-60">
                            <div className="text-center">
                                <i className="u-dot-line-v1-2 g-brd-gray-light-v2--before g-brd-gray-light-v2--after g-mb-20">
                                    <span className="u-dot-line-v1__inner g-bg-white g-bg-primary--before g-brd-gray-light-v2"/>
                                </i>
                                <h3 className="h5 g-color-black mb-20">Create</h3>
                                <p className="mb-0">We begin to visualize your sketches and make them into
                                    beautiful pixels.</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3 g-mb-60">
                            <div className="text-center">
                                <i className="u-dot-line-v1-2 g-brd-gray-light-v2--before g-brd-transparent--after g-mb-20">
                                    <span className="u-dot-line-v1__inner g-bg-white g-bg-primary--before g-brd-gray-light-v2"/>
                                </i>
                                <h3 className="h5 g-color-black mb-20">Release</h3>
                                <p className="mb-0">Your brand is all dressed up and ready to party, it's time
                                    to release.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="g-bg-gray-light-v6">
                <div className="container g-mt-40 g-pb-40">
                    <section className="container u-ns-bg-v1-bottom g-bg-white g-pt-100 g-pb-70">
                        <div className="row justify-content-center g-mb-60">
                            <div className="col-lg-7">
                                <div className="text-center mb-4">
                                    <h2 className="h3 text-uppercase mb-3">Dynamiczny adres DNS</h2>
                                    <div className="d-inline-block g-width-60 g-height-1 g-bg-black mb-2"></div>
                                    <p className="lead mb-0">We are a creative studio focusing on culture, luxury,
                                        editorial &amp; art. Somewhere between sophistication and simplicity.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 g-mb-10">
                                <div className="media g-mb-15">
                                    <div className="d-flex mr-4">
                                      <span className="g-color-primary g-font-size-26">
                                        <i className="icon-education-087 u-line-icon-pro"/>
                                      </span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="h5 g-color-black mb-20">Creative ideas</h3>
                                        <p className="g-color-gray-dark-v4">We strive to embrace and drive change in our
                                            industry which allows us to keep our clients relevant.</p>
                                        <a className="g-color-main g-font-weight-600 g-font-size-12 g-text-underline--none--hover text-uppercase"
                                           href="#">Learn More</a>
                                    </div>
                                </div>
                                <hr className="g-mt-30"/>
                            </div>
                            <div className="col-lg-4 g-mb-10">
                                <div className="media g-mb-15">
                                    <div className="d-flex mr-4">
                                      <span className="g-color-primary g-font-size-26">
                                        <i className="icon-education-035 u-line-icon-pro"/>
                                      </span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="h5 g-color-black mb-20">Excellent features</h3>
                                        <p className="g-color-gray-dark-v4">We strive to embrace and drive change in our
                                            industry which allows us to keep our clients relevant.</p>
                                        <a className="g-color-main g-font-weight-600 g-font-size-12 g-text-underline--none--hover text-uppercase"
                                           href="#">Learn More</a>
                                    </div>
                                </div>
                                <hr className="g-mt-30"/>
                            </div>

                            <div className="col-lg-4 g-mb-10">
                                <div className="media g-mb-15">
                                    <div className="d-flex mr-4">
                                      <span className="g-color-primary g-font-size-26">
                                        <i className="icon-education-141 u-line-icon-pro"/>
                                      </span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="h5 g-color-black mb-20">Fully responsive</h3>
                                        <p className="g-color-gray-dark-v4">We strive to embrace and drive change in our
                                            industry which allows us to keep our clients relevant.</p>
                                        <a className="g-color-main g-font-weight-600 g-font-size-12 g-text-underline--none--hover text-uppercase"
                                           href="#">Learn More</a>
                                    </div>
                                </div>
                                <hr className="g-mt-30"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 g-mb-30">
                                <div className="media g-mb-15">
                                    <div className="d-flex mr-4">
                                      <span className="g-color-primary g-font-size-26">
                                        <i className="icon-finance-256 u-line-icon-pro"/>
                                      </span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="h5 g-color-black mb-20">Clean code</h3>
                                        <p className="g-color-gray-dark-v4">We strive to embrace and drive change in our
                                            industry which allows us to keep our clients relevant.</p>
                                        <a className="g-color-main g-font-weight-600 g-font-size-12 g-text-underline--none--hover text-uppercase"
                                           href="#">Learn More</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 g-mb-30">
                                <div className="media g-mb-15">
                                    <div className="d-flex mr-4">
                                      <span className="g-color-primary g-font-size-26">
                                        <i className="icon-science-020 u-line-icon-pro"/>
                                      </span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="h5 g-color-black mb-20">Extensive documentation</h3>
                                        <p className="g-color-gray-dark-v4">We strive to embrace and drive change in our
                                            industry which allows us to keep our clients relevant.</p>
                                        <a className="g-color-main g-font-weight-600 g-font-size-12 g-text-underline--none--hover text-uppercase"
                                           href="#">Learn More</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 g-mb-30">
                                <div className="media g-mb-15">
                                    <div className="d-flex mr-4">
                                      <span className="g-color-primary g-font-size-26">
                                        <i className="icon-finance-009 u-line-icon-pro"/>
                                      </span>
                                    </div>
                                    <div className="media-body">
                                        <h3 className="h5 g-color-black mb-20">Modern design</h3>
                                        <p className="g-color-gray-dark-v4">We strive to embrace and drive change in our
                                            industry which allows us to keep our clients relevant.</p>
                                        <a className="g-color-main g-font-weight-600 g-font-size-12 g-text-underline--none--hover text-uppercase"
                                           href="#">Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="shortcode-html">
                        <div className="row no-gutters align-items-center">
                            <div className="col-md-4 g-mb-30">
                                <article className="text-center g-brd-around g-color-gray g-brd-gray-light-v5 g-pa-10">
                                    <div className="g-bg-gray-light-v5 g-pa-30">
                                        <h4 className="text-uppercase g-color-gray-dark-v3 g-font-weight-500 g-mb-10">Starter</h4>
                                        <em className="g-font-style-normal">Ed feugiat porttitor nunc, non</em>

                                        <hr className="g-brd-gray-light-v4 g-my-10"/>

                                        <div className="g-color-primary g-my-20">
                                            <strong
                                                className="d-block g-font-size-30 g-line-height-1_2">$24.00</strong> per
                                            month
                                        </div>

                                        <hr className="g-brd-gray-light-v4 g-mt-10 mb-0"/>

                                        <ul className="list-unstyled g-mb-25">
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">Vivamus a fringilla
                                                lorem, vel faucibus ante. Nunc ullamcorper justo..
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12"><b>10%</b> In hac
                                                habitasse platea
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                                                <b>10gb</b> Praesent egestas ac arcu
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                                                <b>25</b> emails Sed eget aliquet nisl
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                                                <b>No</b> Proin laoreet accumsan nisl non
                                            </li>
                                        </ul>
                                        <a className="btn btn-md rounded-0 u-btn-primary" href="#">Order Now</a>
                                    </div>
                                </article>
                            </div>

                            <div className="col-md-4 g-mb-30">
                                <article className="text-center g-brd-around g-color-gray g-brd-gray-light-v5 g-pa-10">
                                    <div className="g-bg-gray-light-v5 g-px-30 g-py-60">
                                        <h4 className="text-uppercase g-color-gray-dark-v3 g-font-weight-500 g-mb-10">Advanced</h4>
                                        <em className="g-font-style-normal">Ed feugiat porttitor nunc, non</em>

                                        <hr className="g-brd-gray-light-v4 g-my-10"/>

                                        <div className="g-color-primary g-my-20">
                                            <strong
                                                className="d-block g-font-size-30 g-line-height-1_2">$50.00</strong> per
                                            month
                                        </div>

                                        <hr className="g-brd-gray-light-v4 g-mt-10 mb-0"/>

                                        <ul className="list-unstyled g-mb-25">
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">Vivamus a fringilla
                                                lorem, vel faucibus ante. Nunc ullamcorper justo..
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12"><b>20%</b> In hac
                                                habitasse platea
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                                                <b>20gb</b> Praesent egestas ac arcu
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                                                <b>50</b> emails Sed eget aliquet nisl
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                                                <b>Yes</b> Proin laoreet accumsan nisl non
                                            </li>
                                        </ul>
                                        <a className="btn btn-md rounded-0 u-btn-primary" href="#">Order Now</a>
                                    </div>
                                </article>
                            </div>

                            <div className="col-md-4 g-mb-30">
                                <article className="text-center g-brd-around g-color-gray g-brd-gray-light-v5 g-pa-10">
                                    <div className="g-bg-gray-light-v5 g-pa-30">
                                        <h4 className="text-uppercase g-color-gray-dark-v3 g-font-weight-500 g-mb-10">Professional</h4>
                                        <em className="g-font-style-normal">Ed feugiat porttitor nunc, non</em>

                                        <hr className="g-brd-gray-light-v4 g-my-10"/>

                                        <div className="g-color-primary g-my-20">
                                            <strong
                                                className="d-block g-font-size-30 g-line-height-1_2">$75.00</strong> per
                                            month
                                        </div>

                                        <hr className="g-brd-gray-light-v4 g-mt-10 mb-0"/>

                                        <ul className="list-unstyled g-mb-25">
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">Vivamus a fringilla
                                                lorem, vel faucibus ante. Nunc ullamcorper justo..
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12"><b>30%</b> In hac
                                                habitasse platea
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                                                <b>40gb</b> Praesent egestas ac arcu
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                                                <b>100</b> emails Sed eget aliquet nisl
                                            </li>
                                            <li className="g-brd-bottom g-brd-gray-light-v4 g-py-12">
                                                <b>Yes</b> Proin laoreet accumsan nisl non
                                            </li>
                                        </ul>
                                        <a className="btn btn-md rounded-0 u-btn-primary" href="#">Order Now</a>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>

                    <div className="shortcode-html">
                        <header className="u-heading-v8-2 text-center g-width-70x--md mx-auto g-mb-60">
                            <h2 className="u-heading-v8__title text-uppercase g-font-weight-600 g-mb-25">Contact us</h2>
                            <p className="lead mb-0">We do things differently company providing key digital services.
                                Focused on
                                helping our clients to build a successful business on web and mobile.</p>
                        </header>

                        <div className="row">
                            <div className="col-md-7">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6 form-group g-mb-30">
                                            <input
                                                className="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus rounded-0 g-py-13 g-px-15"
                                                type="text" placeholder="Your name"/>
                                        </div>

                                        <div className="col-md-6 form-group g-mb-30">
                                            <input
                                                className="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus rounded-0 g-py-13 g-px-15"
                                                type="tel" placeholder="Your phone"/>
                                        </div>

                                        <div className="col-md-12 form-group g-mb-30">
                                            <input
                                                className="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus rounded-0 g-py-13 g-px-15"
                                                type="text" placeholder="Subject"/>
                                        </div>

                                        <div className="col-md-12 form-group g-mb-30">
                                    <textarea
                                        className="form-control g-color-gray-dark-v5 g-bg-white g-bg-white--focus g-brd-gray-light-v4 g-brd-primary--focus g-resize-none rounded-0 g-py-13 g-px-15"
                                        rows="7" placeholder="Message"/>
                                        </div>
                                    </div>

                                    <button className="btn u-btn-primary rounded-0 g-py-12 g-px-20" type="submit"
                                            role="button">Send Message
                                    </button>
                                </form>
                            </div>

                            <div className="col-md-5">
                                <div className="lead g-mb-40">
                                    <p>Want to get more information? Contact us by using the form or call directly for
                                        more
                                        info.</p>
                                </div>

                                <address className="text-uppercase mb-0">
                                    <div className="d-flex g-mb-20">
                                        <div
                                            className="u-icon-v3 u-icon-size--sm g-font-size-default g-bg-gray-dark-v2 g-color-white-opacity-0_8 rounded-circle g-mr-15">
                                            <i className="icon-user"/>
                                        </div>
                                        <div className="align-self-center g-font-weight-600 g-font-size-12">
                                            Address:
                                            <br/>
                                            <strong className="g-color-gray-dark-v2">Orange California, US </strong>
                                        </div>
                                    </div>

                                    <div className="d-flex g-mb-20">
                                        <div
                                            className="u-icon-v3 u-icon-size--sm g-font-size-default g-bg-gray-dark-v2 g-color-white-opacity-0_8 rounded-circle g-mr-15">
                                            <i className="icon-speech"/>
                                        </div>
                                        <div className="align-self-center g-font-weight-600 g-font-size-12">
                                            Email:
                                            <br/>
                                            <a className="g-color-gray-dark-v2 g-color-gray-dark-v2--hover"
                                               href="mailto:marketing@unify.com">
                                                <strong>info@htmlstream.com</strong></a>
                                        </div>
                                    </div>

                                    <div className="d-flex">
                                        <div
                                            className="u-icon-v3 u-icon-size--sm g-font-size-default g-bg-gray-dark-v2 g-color-white-opacity-0_8 rounded-circle g-mr-15">
                                            <i className="icon-screen-smartphone"/>
                                        </div>
                                        <div className="align-self-center g-font-weight-600 g-font-size-12">
                                            Phone number:
                                            <br/>
                                            <strong className="g-color-gray-dark-v2">+1 555 2566 112</strong>
                                        </div>
                                    </div>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterTopComponent/>
            <FooterBottomComponent/>
        </main>
    );
}
