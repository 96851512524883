import React from "react";
import {Link} from "react-router-dom";
import config from "../../config";

export default class LoginBarWidget extends React.Component {
    render() {
        if (config.accounts.enabled) {
            return (
                <div className="col-auto g-px-15">
                    <i className="fa fa-sign-in mcolor g-mr-5"></i>
                    <Link className="whitelink wltop" to={config.accounts.login.url}>{config.accounts.login.title}</Link>
                </div>
            );
        }
        return null;
    }
}
