import HeaderComponent from "../Components/HeaderComponent";
import FooterTopComponent from "../Components/FooterTopComponent";
import FooterBottomComponent from "../Components/FooterBottomComponent";
import React from "react";
import config from "../config";

export default function GdprPage() {
    return (
        <main>
            <HeaderComponent/>
            <h1>{config.pages.gdpr.title}</h1>

            <FooterTopComponent />
            <FooterBottomComponent />
        </main>
    );
}
