import React from "react";

export default class PromoBlockComponent extends React.Component {
    render() {

        return <section className="g-pos-rel">
            <div className="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll"
                 data-options='{direction: "reverse", settings_mode_oneelement_max_offset: "150"}'>
                <div
                    className="divimage dzsparallaxer--target w-100 g-bg-cover g-bg-pos-top-center g-bg-img-hero g-bg-bluegray-opacity-0_2--after"
                    style={{height: "130%", backgroundImage: "url(/assets/img/bg/bg_head.jpg" }}>
                </div>

                <div className="container g-bg-cover__inner g-py-100">
                    <div className="row align-items-center">
                        <div>
                            <h2 className="h1 text-uppercase g-color-white g-mb-30">
                                <span className="g-bg-primary g-px-5">Szukasz wykonawcy </span><br/>
                                <span className="g-bg-primary-dark-v3 g-px-5">swojego projektu?</span><br/>
                            </h2>
                            <h3 className="h4 g-color-white">
            <span className="g-bg-black-opacity-0_6 g-px-5">
                Nasz zespół posiada ponad 10 lat doświadczenia w tworzeniu stron internetowych.</span><br/>
                            </h3>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    }
}
