import React from "react";
import {Link} from "react-router-dom";
import config from "../../config";

export default class RegistationBarWidget extends React.Component {
    render() {
        if (config.accounts.enabled) {
            return (
                <div className="col-auto g-px-15">
                    <i className="fa fa-handshake-o mcolor g-mr-5"></i>
                    <Link className="whitelink wltop" to="/rejestracja">Rejestracja</Link>
                </div>
            );
        }
        return null;
    }
}
