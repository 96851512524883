import HeaderComponent from "../Components/HeaderComponent";
import FooterTopComponent from "../Components/FooterTopComponent";
import FooterBottomComponent from "../Components/FooterBottomComponent";
import BreadcrumbsComponent from "../Components/BreadcrumbsComponent";
import React, {useEffect} from "react";

export default function ContactPage() {

    useEffect(() => {
        document.title = 'Strony internetowe consulting IT | pyCode.biz';
    });

    return (
        <main>
            <HeaderComponent/>
            <BreadcrumbsComponent/>

            <section className="container g-pt-100 g-pb-40">
                <div className="row justify-content-between">
                    <div className="col-md-7 g-mb-60">
                    </div>

                    <div className="col-md-4">
                        <h1 className="g-font-weight-300 mb-5">pyCode.biz</h1>

                        <div className="mb-4">
                            <h2 className="h5 g-color-gray-dark-v2 g-font-weight-600">pyCode.biz </h2>
                            <p className="mb-0">
                                pyCode.biz jest marką <br />
                                TerraLabsMedia Sp. z o. o.<br/>
                                ul. F. D. Roosevelta 26<br/>
                                50-236 Wrocław<br/> NIP: PL8982254142</p>

                        </div>

                        <div className="mb-4">
                            <h2 className="h5 g-color-gray-dark-v2 g-font-weight-600">Napisz e-maila:</h2>
                            <p className="g-color-gray-dark-v4">E-mail: <a className="g-color-gray-dark-v2"
                                                                           href="mailto:info@pycode.biz">info@pybode.biz</a>
                            </p>
                        </div>

                        <div className="mb-3">
                            <h2 className="h5 g-color-gray-dark-v2 g-font-weight-600">Zadzwoń:</h2>
                            <p className="g-color-gray-dark-v4">Call: <span className="g-color-gray-dark-v2">(+48) 785-835-825</span>
                            </p>
                        </div>

                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <a className="u-icon-v1 g-color-gray-dark-v5 g-bg-gray-light-v5 g-color-white--hover g-bg-primary--hover rounded-circle"
                                   href="https://www.facebook.com/pyCode.biz/">
                                    <i className="g-font-size-default fa fa-facebook"/>
                                </a>
                            </li>
                            <li className="list-inline-item g-mx-4">
                                <a className="u-icon-v1 g-color-gray-dark-v5 g-bg-gray-light-v5 g-color-white--hover g-bg-primary--hover rounded-circle"
                                   href="https://linkedin.com/company/pycode-biz/about/">
                                    <i className="g-font-size-default fa fa-linkedin"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <FooterTopComponent/>
            <FooterBottomComponent/>
        </main>
    );
}
