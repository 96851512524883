import React from "react";


export default class FooterBottomComponent extends React.Component {
    render() {
        return (
            <footer className="g-bg-gray-dark-v1 g-color-white-opacity-0_8 g-py-20">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 text-center text-md-left g-mb-10 g-mb-0--md">
                            <div className="d-lg-flex">
                                <small className="d-block g-font-size-default g-mr-10 g-mb-10 g-mb-0--md">© 2013
                                    pyCode.biz.
                                    All Rights Reserved.</small>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center">
                            <ul className="list-inline text-center text-md-right mb-0">
                                <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top"
                                    title="Facebook">
                                    <a href="https://www.facebook.com/pyCode.biz/"
                                       className="g-color-white-opacity-0_5 g-color-white--hover">
                                        <i className="fa fa-facebook"/>
                                    </a>
                                </li>
                                <li className="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top"
                                    title="Linkedin">
                                    <a href="https://www.linkedin.com/company/pycode-biz/"
                                       className="g-color-white-opacity-0_5 g-color-white--hover">
                                        <i className="fa fa-linkedin"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </footer>
        );
    }
}
