import React from "react";
import {Link} from "react-router-dom";
import StatusBarWidget from "../Modules/Status/StatusBarWidget";
import config from "../config";
import MyAccountWidget from "../Modules/Accounts/MyAccountWidget";
import RegistationBarWidget from "../Modules/Status/RegistrationBarWidget";
import LoginBarWidget from "../Modules/Status/LoginBarWidget";

export default class HeaderComponent extends React.Component {
    render() {
        return <header id="js-header" className="u-header u-header--static">
            {config.topBar === true &&
            <div className="u-header__section u-header__section--hidden u-header__section--dark g-bg-black g-py-7">
                <div className="container">
                    <div
                        className="row flex-column flex-md-row align-items-center justify-content-md-end  g-font-weight-600 g-color-white g-font-size-13">
                        <StatusBarWidget/>
                        <MyAccountWidget/>
                        <RegistationBarWidget/>
                        <LoginBarWidget/>
                    </div>
                </div>
            </div>
            }
            <div className="u-header__section u-header__section--light g-bg-white g-transition-0_3 g-py-10">
                <nav className="js-mega-menu navbar navbar-expand-lg">
                    <div className="container">
                        <button
                            className="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0"
                            type="button" aria-label="Toggle navigation" aria-expanded="false"
                            aria-controls="navBar"
                            data-toggle="collapse" data-target="#navBar">
          <span className="hamburger hamburger--slider">
              <span className="hamburger-box">
              <span className="hamburger-inner"></span>
          </span>
          </span>
                        </button>
                        <Link to="/" className="navbar-brand">
                            <img src={'/assets/img/logo/logo_top.png'} alt=""/>
                        </Link>
                        <div
                            className="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg g-mr-40--lg"
                            id="navBar">
                            <ul className="navbar-nav text-uppercase g-pos-rel g-font-weight-600 ml-auto">
                                <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                                    <Link to="/" className="nav-link g-py-7 g-px-0">Strona Główna</Link>
                                </li>

                                {config.portfolio.enabled === true &&
                                <li className="nav-item  g-mx-10--lg g-mx-15--xl">
                                    <Link to={config.portfolio.url}
                                          className="nav-link g-py-7 g-px-0">{config.portfolio.title}</Link>
                                </li>
                                }
                                {config.services.types.main.enabled === true &&
                                <li className="hs-has-sub-menu nav-item g-mx-10--lg g-mx-15--xl"
                                    data-animation-in="fadeIn" data-animation-out="fadeOut">
                                    <Link id="nav-link-pages" className="nav-link g-py-7 g-px-0"
                                          to={config.services.types.main.url}
                                          aria-haspopup="true" aria-expanded="false"
                                          aria-controls="nav-submenu-pages">{config.services.types.main.title}</Link>

                                    <ul className="hs-sub-menu list-unstyled u-shadow-v11 g-brd-top g-brd-primary g-brd-top-2 g-min-width-220 g-mt-18 g-mt-8--lg--scrolling animated fadeOut"
                                        id="nav-submenu-pages" aria-labelledby="nav-link-pages">
                                        {config.services.types.dynamicDns.enabled === true &&
                                        <li className="dropdown-item">
                                            <Link
                                                to={config.services.types.dynamicDns.url}
                                                className="nav-link"
                                            >{config.services.types.dynamicDns.title}</Link>
                                        </li>
                                        }
                                        {config.services.types.hosting.enabled === true &&
                                        <li className="nav-item">
                                            <Link to={config.services.types.hosting.url}
                                                  className="nav-link"
                                            >{config.services.types.hosting.title}</Link>
                                        </li>
                                        }
                                        {config.services.types.webdesign.enabled === true &&
                                        <li className="nav-item">
                                            <Link to={config.services.types.webdesign.url}
                                                  className="nav-link"
                                            >{config.services.types.webdesign.title}</Link>
                                        </li>
                                        }
                                        {config.services.types.customApplication.enabled === true &&
                                        <li className="nav-item">
                                            <Link
                                                to={config.services.types.customApplication.url}
                                                className="nav-link"
                                            >{config.services.types.customApplication.title}</Link>
                                        </li>
                                        }
                                    </ul>
                                </li>
                                }
                            </ul>
                        </div>
                        {config.contact.enabled === true &&
                        <div className="d-inline-block g-hidden-xs-down g-pos-rel g-valign-middle g-pl-30 g-pl-0--lg">
                            <Link className="btn u-btn-outline-primary g-font-size-13 text-uppercase g-py-10 g-px-15"
                                  to={config.contact.url}>{config.contact.title}</Link>
                        </div>
                        }
                    </div>
                </nav>
            </div>
        </header>
    }
}
