import React from "react";

export default class TechnologiesComponent extends React.Component {
    render() {
        return <section className="g-bg-white g-pa-30">
            <div className="d-md-flex justify-content-md-center text-center">
                <div className="align-self-md-center">
                    <h4 className="g-font-weight-200">Wykorzystujemy tylko sprawdzone technologie:</h4>
                    <img src="/assets/img/logo/ansible.png" alt="Pycode.biz"/>
                    <img src="/assets/img/logo/python.png" alt="Python"/>
                    <img src="/assets/img/logo/html5.png" alt="html5"/>
                    <img src="/assets/img/logo/css3.png" alt="css3"/>
                    <img src="/assets/img/logo/bootstrap.png" alt="bootstrap"/>
                    <img src="/assets/img/logo/yii.png" alt="yii framework"/>
                    <img src="/assets/img/logo/nodejs.png" alt="node-js"/><br/>
                    <img src="/assets/img/logo/react.png" alt="react"/>
                    <img src="/assets/img/logo/prestashop.png" alt="Prestashop"/>
                    <img src="/assets/img/logo/wordpress.png" alt="Wordpress"/>
                    <img src="/assets/img/logo/nginx.png" alt="Nginx"/>
                </div>
            </div>
        </section>
    }
}
