import React from "react";
import {Link} from "react-router-dom";
import config from "../../config";

export default class MyAccountWidget extends React.Component {
    render() {
        if (config.accounts.enabled) {
            return (
                <div className="col-auto g-px-15">
                    <Link to={config.accounts.profile.url}>{config.accounts.profile.title}</Link>
                </div>
            );
        }
        return null;
    }
}

