import React from "react";
import config from "../config";
import {Link} from "react-router-dom";

export default class FooterTopComponent extends React.Component {
    render() {
        return <footer className="g-bg-black-opacity-0_9 g-color-white-opacity-0_8 g-py-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="u-heading-v2-3--bottom g-brd-white-opacity-0_8 g-mb-20">
                            <h2 className="u-heading-v2__title h6 text-uppercase mb-0">Kontakt</h2>
                        </div>
                        <address className="g-bg-no-repeat g-font-size-12 mb-0"
                                 style={{backgroundImage: "url(/assets/img/maps/map2.png"}}>
                            <div className="d-flex g-mb-20">
                                <div className="g-mr-10">
              <span className="u-icon-v3 u-icon-size--xs g-bg-white-opacity-0_1 g-color-white-opacity-0_6">
                <i className="fa fa-map-marker"/>
              </span>
                                </div>
                                <p className="mb-0">
                                    pyCode.biz jest marką <br/>
                                    TerraLabsMedia Sp. z o. o.<br/>
                                    ul. F. D. Roosevelta 26<br/>
                                    50-236 Wrocław<br/> NIP: PL8982254142
                                </p>
                            </div>
                            <div className="d-flex g-mb-20">
                                <div className="g-mr-10">
              <span className="u-icon-v3 u-icon-size--xs g-bg-white-opacity-0_1 g-color-white-opacity-0_6">
                <i className="fa fa-phone"/>
              </span>
                                </div>
                                <p className="mb-0">(+48) 785-835-825</p>
                            </div>
                            <div className="d-flex g-mb-20">
                                <div className="g-mr-10">
              <span className="u-icon-v3 u-icon-size--xs g-bg-white-opacity-0_1 g-color-white-opacity-0_6">
                <i className="fa fa-globe"/>
              </span>
                                </div>
                                <p className="mb-0">
                                    <a className="g-color-white-opacity-0_8 g-color-white--hover"
                                       href="mailto:info@pycode.biz">info@pycode.biz</a>
                                    <br/>
                                    <a className="g-color-white-opacity-0_8 g-color-white--hover"
                                       href="https://pycode.biz/">www.pycode.biz</a>
                                </p>
                            </div>
                        </address>
                    </div>
                    <div className="col-md-6 col-lg  align-self-center text-center">
                        <img className="align-self-center align-middle" src="/assets/img/logo/pycode.png"
                             alt="Pycode.biz"/>
                    </div>
                    {(config.pages.enabled === true) &&
                    <div className="col-md-6 col-lg ">
                        <h2 className="h6 g-color-white u-heading-v3__title text-uppercase g-font-weight-700 g-mb-20">Na
                            skróty</h2>
                        <div className="row">
                            <div className="col-sm-6">
                                <ul className="list-unstyled g-mb-30 g-mb-0--md">
                                    <li className="d-flex g-mb-10">
                                        <i className="fa fa-circle g-color-primary g-font-size-5 g-mt-10 g-mr-8"/>
                                        <Link className="g-color-white-opacity-0_8 g-color-primary--hover"
                                              to={config.pages.terms.url}>{config.pages.terms.title}</Link>
                                    </li>
                                    <li className="d-flex g-mb-10">
                                        <i className="fa fa-circle g-color-primary g-font-size-5 g-mt-10 g-mr-8"/>
                                        <Link className="g-color-white-opacity-0_8 g-color-primary--hover"
                                              to={config.pages.privacy.url}>{config.pages.privacy.title}</Link>
                                    </li>

                                    <li className="d-flex g-mb-10">
                                        <i className="fa fa-circle g-color-primary g-font-size-5 g-mt-10 g-mr-8"/>
                                        <Link className="g-color-white-opacity-0_8 g-color-primary--hover"
                                              to={config.pages.cookies.url}>{config.pages.cookies.title}</Link>
                                    </li>

                                    <li className="d-flex g-mb-10">
                                        <i className="fa fa-circle g-color-primary g-font-size-5 g-mt-10 g-mr-8"/>
                                        <Link className="g-color-white-opacity-0_8 g-color-primary--hover"
                                              to={config.pages.gdpr.url}>{config.pages.gdpr.title}</Link>
                                    </li>
                                </ul>
                            </div>
                            {(config.services.types.hosting === true || config.services.types.psd2html === true) &&
                            <div className="col-sm-6">
                                <ul className="list-unstyled mb-0">
                                    <li className="d-flex g-mb-10">
                                        <i className="fa fa-circle g-color-primary g-font-size-5 g-mt-10 g-mr-8"/>
                                        <Link className="g-color-white-opacity-0_8 g-color-primary--hover"
                                              to={config.services.types.hosting.url}>{config.services.types.hosting.url}</Link>
                                    </li>
                                    <li className="d-flex g-mb-10">
                                        <i className="fa fa-circle g-color-primary g-font-size-5 g-mt-10 g-mr-8"/>
                                        <Link className="g-color-white-opacity-0_8 g-color-primary--hover"
                                              to={config.services.types.psd2html.url}>{config.services.types.psd2html.title}</Link>
                                    </li>
                                </ul>
                            </div>
                            }
                        </div>
                    </div>
                    }
                </div>
            </div>
        </footer>;
    }
}
