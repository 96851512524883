import React from "react";
import {Link} from "react-router-dom";
import config from "../../config";

export default class StatusBarWidget extends React.Component {
    render() {
        if (config.status.enabled) {
            return (
                <div className="col-auto g-px-15">
                    <i className="fa fa-info mcolor g-mr-5"/>
                    <Link className="whitelink wltop" to={config.status.url}>{config.status.title}</Link>
                </div>
            );
        }
        return null;
    }
}

