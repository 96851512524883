import React from 'react';

import './App.css';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import config from "./config";

// Import Pages
import HomePage from "./Pages/HomePage";
import ErrorPage from "./Pages/ErrorPage";
import CookiesPage from "./Pages/CookiesPage";
import GdprPage from "./Pages/GdprPage";
import TermsPage from "./Pages/TermsPage";
import PrivacyPage from "./Pages/PrivacyPage";
import ContactPage from "./Pages/ContactPage";
import DynamicDnsPage from "./Pages/Services/DynamicDnsPage";
import MainServicesPage from "./Pages/Services/MainServicesPage";


export default function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/">
                        <HomePage/>
                    </Route>
                    <Route exact path={config.pages.cookies.url}>
                        <CookiesPage/>
                    </Route>
                    <Route exact path={config.pages.gdpr.url}>
                        <GdprPage/>
                    </Route>
                    <Route exact path={config.pages.privacy.url}>
                        <PrivacyPage/>
                    </Route>
                    <Route exact path={config.pages.terms.url}>
                        <TermsPage/>
                    </Route>
                    {config.services.types.dynamicDns.enabled === true &&
                    <Route exact path={config.services.types.dynamicDns.url}>
                        <DynamicDnsPage/>q
                    </Route>
                    }
                    {config.services.types.main.enabled === true &&
                    <Route exact path={config.services.types.main.url}>
                        <MainServicesPage/>
                    </Route>
                    }
                    <Route path={config.contact.url}>
                        <ContactPage/>
                    </Route>

                    <Route path="*">
                        <ErrorPage/>
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}
