import React from "react";


export default class breadcrumb extends React.Component {
    render() {
        return (
            <section className="g-bg-gray-light-v5 g-py-50">
                <div className="container">
                    <div className="d-sm-flex text-center">
                        <div className="align-self-center">
                            <h2 className="h3 g-font-weight-300 w-100 g-mb-10 g-mb-0--md">Kontakt</h2>
                        </div>

                        <div className="align-self-center ml-auto">
                            <ul className="u-list-inline">
                                <li className="list-inline-item g-mr-5">
                                    <a className="u-link-v5 g-color-main g-color-primary--hover" href="/">Strona Główna</a>
                                    <i className="g-color-gray-light-v2 g-ml-5">/</i>
                                </li>
                                <li className="list-inline-item g-color-primary">
                                    <span>Kontakt</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
